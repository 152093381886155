.not_found_div {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  justify-content: center;
  align-items: center;
}

.not_found_image {
  width: 300px;
  max-width: 95%;
  padding-top: 8rem;
}

.navigate_button {
  background-color: var(--black);
  border-radius: 8px;
  padding: 7px 12px;
  border: none;
  color: #fff;
  letter-spacing: 0.09em;
  transition: all 0.3s ease-in-out;
  margin: 2rem;
  cursor: pointer;
}

.navigate_button:hover {
  background-color: var(--primary);
}

@media screen and (max-width: 576px) {
  .navigate_button {
    padding: 5px 10px;
    font-size: 15px;
  }
}
