@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100..900;1,100..900&display=swap');

:root {
  --primary: #74af85;
  --primaryBlue: #a9cfbc;
  --secondary: #0c3944;
  --lightBlue: #c2e2eb;
  --lightGreen: #ecfff1;
  --white: #fff;
  --black: #1c1c1c;
  --disabled: #858585;
  --pink: #f06a80;
  --danger: #eb3232;
  --dark: #676767;
  --red: #d95e70;
  --border: #d9d9d9;
  --purple: #dc6de8;
  --orange: #fac360;
  --yellow: #e4b80a;
  --dark-orange: #ee7f1d;
  --cyan: #1fa79d;
  --grey: #e6e6e6;
  --dark-grey: #cac6c6;
  --darker-grey: #504e4e;
  --light-blue: #e1f7fa;
  --medium-blue: #6d8acc;
  --darker-blue: #242e6c;
  --dark-blue: #1fa8a0;
  --bottom-grey: #707070;
  --bold: 'bold';
  --regular: 'regular';
  --light: 200;

  --px-50: 50px;
  --px-40: 40px;
  --px-30: 30px;
  --px-25: 25px;
  --px-20: 20px;
  --px-18: 18px;
  --px-16: 16px;
  --px-14: 14px;
  --px-12: 12px;
  --px-10: 10px;

  --shadow: 5px 5px 5px rgba(0, 0, 0, 0.3) !important;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box !important;
  /* scroll-behavior: smooth; */
}

body {
  font-family: 'Raleway', sans-serif;
  color: #0c3944;
}

/* .margin-container{
  height: 90px;
}

.error_msg{
  font-size: 0.8rem;
  color: var(--pink);
  margin-bottom: 1rem;
}
.fa-spin {
  animation-name: spin;
  animation-duration: 5000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;}

  @keyframes spin {
    from {
        transform:rotate(0deg);
    }
    to {
        transform:rotate(360deg);
    }
}
.input_field{
  background-color: white;
  border: 2px solid var(--border);
  border-radius: 10px;
  padding:5px 15px;
  margin-bottom: 1rem;
  width: 100%;
}

.input_field ::placeholder{
  color: var(--border);
}

.primary_button{
  border-radius: 8px;
  background-color: var(--primary);
  color: white;
  font-size: 1rem;
  padding:7px 12px;
  border: none;
  outline: none;
  cursor: pointer;
}

.register_button{
  border-radius: 8px;
  background-color: var(--secondary);
  color: white;
  font-size: 1rem;
  padding:7px 12px;

  border: none;
  outline: none;
  cursor: pointer;
  

}

.divider_line{
  border-bottom: 4px dotted var(--grey);
}
button,
input,
optgroup,
select,
textarea {
  margin: 0;
  color: inherit;
  font-size: inherit;
  font-family: inherit;
  line-height: inherit;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5em;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
}

.auth_layout_white_container {
  min-height: 200px;
  background-color: var(--white);
  height: 100%;
  z-index: 2;
  position: relative;
}

.auth-layout-image-position {
  z-index: 1;
  position: absolute;
  top: -100px;
  right: -100px;
  height: 200px;
  object-fit: contain;
}

.font-bold {
  font-family: var(--bold) !important;
}

.font-regular {
  font-family: var(--regular) !important;
}

.font-light {
  font-family: var(--light) !important;
}

.font-50 {
  font-size: var(--px-50) !important;
}

.font-30 {
  font-size: var(--px-30) !important;
}

.font-20 {
  font-size: var(--px-20) !important;
}

.font-18 {
  font-size: var(--px-18) !important;
}

.font-16 {
  font-size: var(--px-16) !important;
}

.font-14 {
  font-size: var(--px-14) !important;
}

.font-12 {
  font-size: var(--px-12) !important;
}

.font-10 {
  font-size: var(--px-10) !important;
}

@media (max-width: 1000px) {
  .font-50 {
    font-size: 30px !important;
  }

  .font-30 {
    font-size: 25px !important;
  }

  .font-20 {
    font-size: 18px !important;
  }

  .font-18 {
    font-size: 16px !important;
  }

  .font-14 {
    font-size: 12px !important;
  }

  .font-12 {
    font-size: 10px !important;
  }

  .font-10 {
    font-size: 8px !important;
  }
}


.no-padding {
  padding: 0 !important;
}

.no-margin {
  margin: 0 !important;
}

.primary-b {
  color: var(--primary) !important;
}

.secondary-b {
  color: var(--secondary) !important;
}

.danger-red {
  color: var(--danger) !important;
}

.white {
  color: var(--white) !important;
}

.dark {
  color: var(--dark) !important;
}

.grey {
  color: var(--grey);
}

.dark-grey {
  color: var(--dark-grey);
}

.purple {
  color: var(--purple);
}

.dark-blue {
  color: var(--dark-blue);
}

.uppercase {
  text-transform: uppercase;
}

.pointer {
  cursor: pointer;
}

.main-row {
  height: 100%;
}

.globalContainer {
  margin-inline: auto !important;
}

@media (max-height: 750px) {
  .main-row {
    height: auto;
  }
} */
